export const environment = {
  production: true,
  mode: 'prod',
  APP_REGISTRATION: {
    CLIENT_ID: '2d5174b0-3c08-4543-b568-be7c1f2b91bc',
  },
  BACKEND_URL: 'https://stellargpt-eur.ab-inbev.com/stellar',
  GATEWAY_URL: 'https://stellargpt-eur.ab-inbev.com/genai',
  APPLICATION_ID: 'e8913a93-05c1-49fe-8476-27a09f6edfbd',
  PERSONAL_RAG_CONNECTOR_ID: '882acc17-6ab7-4940-9358-8cc47c4a0414',
  APIM_SUBSCRIPTION_KEY: '',
  APP_INSIGHTS: {
    INSTRUMENTATION_KEY: '',
    CONNECTION_STRING: '',
  },
  APPLICATION_LABEL: 'Stellar',
  LAYOUT: {
    PERSONAL_RAG: false,
    CONTEXT_SELECTOR: false,
  },
};
